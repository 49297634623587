.formWrapper {
	position: relative;
}

.formError {
	position: absolute;
	top: 20px;
	left: 0;
	composes: g-grid-container from global;

	@media (--medium-up) {
		top: 40px;
	}
}

.teaserContainer {
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-09);
	text-align: left;
}

.teaserHeading {
	composes: heading from 'components/stepped-form/style.module.css';
}

.progressWrapper {
	composes: g-grid-container from global;

	@media (--medium-up) {
		top: var(--hdsplus-spacing-15);
	}

	top: var(--hdsplus-spacing-08);
}

.imgContainer {
	grid-column: 1 / -1;
	@media (--medium-up) {
		grid-column: 7 / -1;
	}

	@media (--large) {
		--columns: 6;

		grid-row: 1 / -1;
	}
	.teaserImg {
		width: 100%;
		height: auto;
		object-fit: contain;
	}
}
