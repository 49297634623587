$hds-button-border-width: 1px;
$hds-button-focus-border-width: 3px;
$hds-button-border-radius: 5px;

@mixin mds-button-color-product($product) {
  color: var(--hdsplus-color-#{$product}-button-color);
  background-color: var(--hdsplus-color-#{$product}-button-background-color);
  border-color: var(--hdsplus-color-#{$product}-button-border-color, var(--token-color-border-primary));
  box-shadow: var(--token-elevation-low-box-shadow);

  &:hover,
  &.mock-hover {
    color: var(--hdsplus-color-#{$product}-button-color);
    background-color: var(--hdsplus-color-#{$product}-button-background-color-hover);
    cursor: pointer;
  }

  &:focus,
  &.mock-focus {
    color: var(--hdsplus-color-#{$product}-button-color);
    background-color: var(--hdsplus-color-#{$product}-button-background-color-focus);

    &::before {
      /* the position absolute of an element is computed from the inside of the border of the container
      so we have to take in account the border width of the pseudo-element container itself
      plus for the primary button we want to have a 2px gap between the button and the focus */
      $shift: $hds-button-border-width + $hds-button-focus-border-width + 2px;
      top: -$shift;
      right: -$shift;
      bottom: -$shift;
      left: -$shift;
      border-color: var(--token-color-focus-action-external);
      border-radius: $hds-button-border-radius + $hds-button-focus-border-width + 2px;
    }
  }

  &:active,
  &.mock-active {
    color: var(--hdsplus-color-#{$product}-button-color);
    background-color: var(--hdsplus-color-#{$product}-button-background-color-active);
    box-shadow: none;

    &::before {
      border-color: transparent;
    }
  }
}

.mds-button--color-primary-white {
	@include mds-button-color-product('primary-white');
}

.mds-button--color-primary-black {
	@include mds-button-color-product('primary-black');
}

.mds-button--color-secondary-white {
	@include mds-button-color-product('secondary-white');
}

.mds-button--color-secondary-black {
	@include mds-button-color-product('secondary-black');
}

:global(.hdsplus-dark-mode),
:global([data-theme='dark']) {
	& .mds-button--color-secondary-high-contrast {
		@include mds-button-color-product('secondary-white');
	}
}

.mds-button--color-secondary-high-contrast {
	@include mds-button-color-product('secondary-black');
}

.mds-button--color-boundary {
	@include mds-button-color-product('boundary');
}

.mds-button--color-consul {
	@include mds-button-color-product('consul');
}

.mds-button--color-nomad {
	@include mds-button-color-product('nomad');
}

.mds-button--color-packer {
	@include mds-button-color-product('packer');
}

.mds-button--color-terraform {
	@include mds-button-color-product('terraform');
}

.mds-button--color-vagrant {
	@include mds-button-color-product('vagrant');
}

.mds-button--color-vault {
	@include mds-button-color-product('vault');
}

.mds-button--color-waypoint {
	@include mds-button-color-product('waypoint');
}