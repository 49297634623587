.root {
	position: relative;
	overflow-x: hidden;
}

.inner {
	composes: g-grid-container from global;
	position: relative;
}

.header {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: baseline;
	margin-bottom: var(--hdsplus-spacing-08);

	@media (--medium-up) {
		flex-direction: row;
		align-items: flex-end;
		margin-bottom: var(--hdsplus-spacing-10);
	}
}

.controls {
	display: flex;
	justify-content: space-between;
	pointer-events: none;
	gap: var(--hdsplus-spacing-05);
	margin-top: var(--hdsplus-spacing-05);

	@media (--medium-up) {
		margin-top: 0;
	}
}

.button {
	display: grid;
	place-items: center;
	flex-shrink: 0;
	pointer-events: auto;
	background: none;
	appearance: none;
	border: none;
	padding: 0;

	&:focus-visible {
		outline: transparent;
	}
}

.carouselContainer {
	position: relative;

	/* 
		Adding 25% extra (padding) and subracting (margin) on the left and right
		provides an area to apply the gradient mask-image to fade out the carousel
		content outside of the main content area.
		The 1px extra prevents cropping of the carousel due to overflow hidden 
		on the root class.
	*/
	padding: 1px calc(25% + 1px);
	margin-inline: -25%;
	mask-image: linear-gradient(90deg, transparent, black 20%, black 80%, transparent);
}

.carousel {
	display: flex;
	flex-wrap: nowrap;
	gap: var(--gap);
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.item {	
	/* 
		We need to account for gaps when determining the width of each item. 
		There is always 1 less gap than the number of visible items.
	*/
	--visibleGaps: calc(var(--visibleItems) - 1);

	/* 
		This determines how much each item's width needs to be offset
		to account for gaps.
	*/
	--itemGapOffset: calc(var(--visibleGaps) * var(--gap) / var(--visibleItems));

	display: grid;
	flex-shrink: 0;
	width: calc(100% / var(--visibleItems) - var(--itemGapOffset));
}


.icon {
	height: 100%;
	width: 100%;
	display: flex;
}
