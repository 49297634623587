.caseStudyHeader {
	background-color: var(--black);
	color: var(--white);
	display: flex;
	flex-wrap: wrap;
	position: relative;

	&.hasPaddingBottom {
		@media (min-width: 1600px) {
			padding-bottom: var(--hdsplus-spacing-15);
		}
	}
}

.innerHeader {
	/* This is to ensure the header content is above the hero image */
	z-index: 1;

	@media (--small) {
		padding-bottom: 3em;
		padding-top: 3em;
	}

	@media (--medium-up) {
		padding-bottom: 6em;
		padding-top: 9em;
	}

	@media (--large) {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
}

.heroMediaWrapper {
	height: auto;
	margin: 0 auto;
	max-width: 1600px;
	width: 100%;
}

.productsUsed {
	flex: 0 1 20%;
	margin-bottom: var(--hdsplus-spacing-10);
}

.productUsedText {
	color: var(--gray-3);
	margin: 0;
}

.productsUsedLogos {
	display: grid;
	grid-template-columns: repeat(4, max-content);
	gap: var(--hdsplus-spacing-07);
	margin-top: var(--hdsplus-spacing-07);
}

.headlineWrapper {
	flex: 0 1 60%;
}

.logoTitle {
	margin: 0 0 2.375rem;
	display: flex;
	gap: var(--hdsplus-spacing-04);

	@media (--small) {
		width: 100%;
	}
}

.logoTitleCustomer,
.logoTitlePartner,
.logoTitleSpan {
	display: inline-flex;
	align-items: center;
}

.logoTitlePartner,
.logoTitleSpan {
	border-left: 1px solid var(--wpl-neutral-500);
	padding-left: var(--hdsplus-spacing-04);
}

.logoTitleSpan {
	color: var(--gray-3);
}

.logoTitleImg {
	width: auto;
	height: auto;
	max-height: 42px;
	max-width: 120px;

	@media (--medium-up) {
		max-height: none;
	}
}

.headlineTitleContainer {
	flex: 1;
}

.headlineTitle {
	margin: 0 0 1.25rem;
	max-width: 16em;
	width: 75%;

	& > div {
		display: flex;
		flex-direction: column;
		gap: var(--hdsplus-spacing-04);
	}

	& p {
		margin: 0;
	}

	@media (--small) {
		width: 100%;
	}
}

.headlineDescription {
	color: var(--wpl-neutral-200);
	margin: 0 0 var(--hdsplus-spacing-10);

	@media (--medium-up) {
		width: 70%;
	}
}

.headlineTag {
	background: var(--black);
	border: 1px solid var(--gray-2);
	color: var(--white);
	display: inline-block;
	font-family: 'Roboto Mono', monospace;
	font-size: 18px;
	padding: 7px 14px;
	position: relative;
	z-index: 1;

	@media (--small) {
		font-size: 12px;
	}
}

.headlineAccentSlashes {
	&.hashicorp {
		color: var(--brand);
	}

	&.terraform {
		color: var(--terraform);
	}

	&.vault {
		color: var(--vault);
	}

	&.nomad {
		color: var(--nomad);
	}

	&.consul {
		color: var(--consul);
	}
}

.heroMedia {
	background-color: var(--black);
	position: relative;

	&:hover .backgroundImgPoster > * {
		transform: scale(1.05);
	}
}

.backgroundImg {
	position: relative;
}

.backgroundImgPoster {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.5;
	overflow: hidden;

	/*
    When this element reaches its max-width and is no longer full bleed,
    add a border-radius.
  */
	@media (min-width: 1600px) {
		border-radius: 8px;
	}

	& > * {
		transition: transform 0.3s;
	}
}

.videoTriggerBttn {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	padding: 90px 0 114px 0;
	margin: 0 auto;
	border: none;
	background-color: transparent;
	cursor: pointer;
	width: 100%;
	border-radius: 8px;

	@media (--medium) {
		padding: var(--hdsplus-spacing-17) 0 var(--hdsplus-spacing-16) 0;
	}

	@media (--large) {
		padding: 165px 0;
	}
}

.iconWrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 9999px;
	width: 100%;
}

.videoHeadline {
	composes: g-type-display-2 from global;
	color: var(--white);
	text-align: center;
	margin-bottom: 0;
	flex-basis: 68%;

	@media (--medium) {
		flex-basis: 72%;
	}

	@media (--large) {
		flex-basis: 42%;
	}
}
