.root {
	--surrounding-height-bottom: 102px;
	--surrounding-height-top: 74px;
	--surrounding-height: calc(
		var(--surrounding-height-bottom) + var(--surrounding-height-top)
	);

	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	background-color: var(--token-color-surface-faint);
}

.formFields {
	overflow-y: auto;
	composes: g-grid-container from global;
	padding-top: 80px;
	padding-bottom: 80px;
	min-height: max-content;
}

.steps {
	min-height: calc(100vh - var(--surrounding-height) - 160px);
	align-items: center;
	display: grid;
	width: 100%;
}

.stepWrapper {
	margin-bottom: -1.5rem;
	grid-area: 1 / -1;
}

.step {
	composes: g-grid from global;
	&[hidden] {
		display: grid;
	}
}

.intro {
	display: flex;
	flex-direction: column;
	gap: var(--hdsplus-spacing-05);
	grid-column: 1 / -1;
	grid-row: 1 / span 4;
	margin-bottom: var(--hdsplus-spacing-09);

	@media (--medium-up) {
		margin-bottom: 0;
		grid-column: 1 / 7;
	}

	@media (--large) {
		grid-column: 1 / 6;
	}
}

.heading {
	@media (--medium) {
		font-family: var(--hdsplus-typography-display-expressive-400-font-family);
		font-size: var(--hdsplus-typography-display-expressive-400-font-size);
		line-height: var(--hdsplus-typography-display-expressive-400-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-400-letter-spacing
		);
	}

	@media (--large) {
		font-family: var(--hdsplus-typography-display-expressive-500-font-family);
		font-size: var(--hdsplus-typography-display-expressive-500-font-size);
		line-height: var(--hdsplus-typography-display-expressive-500-line-height);
		letter-spacing: var(
			--hdsplus-typography-display-expressive-500-letter-spacing
		);
	}
}

.description {
	@media (--medium-up) {
		font-family: var(--hdsplus-typography-body-300-font-family);
		font-size: var(--hdsplus-typography-body-300-font-size);
		line-height: var(--hdsplus-typography-body-300-line-height);
		letter-spacing: var(--hdsplus-typography-body-300-letter-spacing);
	}
}

.fields {
	grid-column: 1 / -1;
	composes: g-grid from global;

	@media (--medium-up) {
		grid-column: 7 / -1;
	}

	@media (--large) {
		--columns: 6;
	}
}

.field {
	grid-column: 1 / -1;
	margin-bottom: var(--hdsplus-spacing-03);

	@media (--large) {
		grid-column: var(--column-span);
	}
}

.footer {
	width: 100%;
	position: sticky;
	bottom: 0;
	background: white;
	overflow: hidden;
	background-color: var(--token-color-surface-primary);
}

.progressContainer {
	display: flex;
	flex-direction: row;
	gap: var(--hdsplus-spacing-08);
	background-color: var(--token-color-surface-strong);
	align-items: center;
	padding: var(--hdsplus-spacing-05) var(--hdsplus-spacing-08);
	border-radius: 6px;

	@media (--medium-up) {
		padding: var(--hdsplus-spacing-05) var(--hdsplus-spacing-11);
	}
}

.progressText {
	font-family: var(--font-display);
	font-size: var(--hdsplus-typography-display-expressive-100-font-size);
	font-weight: var(--font-weight-bold);
	color: var(--token-color-foreground-faint);
	white-space: nowrap;
	padding: 0;
	margin: 0;
}

.segmentedProgressContainer {
	display: flex;
	flex-direction: row;
	width: 100%;
	gap: var(--hdsplus-spacing-03);
}

.segment {
	height: 6px;
	flex: 1;
	background-color: var(--token-color-border-faint);
	transition: background-color 0.15s ease;
	border-radius: 2px;
}

.segmentActive {
	background-color: var(--token-color-palette-blue-100);
}

.segmentComplete {
	background-color: var(--token-color-foreground-action);
}

.progressTextQuestion {
	display: none;

	@media (--medium-up) {
		display: inline;
	}
}

.progressBar {
	display: flex;
	width: 100%;
	height: 6px;
	background-color: var(--token-color-border-faint);
	border-radius: 6px;
	overflow: hidden;
}

.progress {
	background-color: var(--token-color-foreground-action);
	border-radius: 6px;
}

.buttons {
	composes: g-grid-container from global;
	padding-bottom: var(--hdsplus-spacing-07);
	padding-top: var(--hdsplus-spacing-07);
	display: flex;
	gap: var(--hdsplus-spacing-07);
	grid-column: 1 / -1;
	justify-content: space-between;

	@media (--medium-up) {
		justify-content: flex-end;
	}

	& button {
		@media (--small) {
			flex: 1;
		}
	}
}

.hideLegend {
	/* Visually hidden */
	& legend {
		clip: rect(0 0 0 0);
		clip-path: inset(50%);
		height: 1px;
		overflow: hidden;
		position: absolute;
		white-space: nowrap;
		width: 1px;
	}
}

.centered {
	@media (--medium-up) {
		grid-column: 3 / -3;
		text-align: center;
	}
}
